import {
  autoPlacement,
  autoUpdate,
  flip,
  hide,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react-dom';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { cloneElement, useCallback, useId, useRef, useState } from 'react';

import useClickOutside from '../../hooks/useClickOutside';
import { useFloatingContainer } from '../../providers/FloatingContainerProvider';
import Portal from '../Portal';
import PopoverInner from './PopoverInner';

const Popover = (props) => {
  const { children, className, content, placement, title } = props;

  const popperElementRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const { floatingContainer } = useFloatingContainer();

  const { floatingStyles, middlewareData, refs } = useFloating({
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [
      hide({
        boundary: floatingContainer,
      }),
      offset({ mainAxis: 6 }),
      shift({
        padding: 16,
      }),
      // flip and autoPlacement middleware can not be used both at the same time because they cancel each other
      ...(placement !== 'auto' ? [flip()] : [autoPlacement()]),
    ],
  });

  const id = useId();
  const parentClassName = `popover-parent-${id}`;

  const onClickOutside = (e) => {
    const isClickOnPopper = e.target.closest(`.${CSS.escape(parentClassName)}`);

    if (isClickOnPopper) {
      return;
    }

    setIsOpen(false);
  };

  useClickOutside(popperElementRef, onClickOutside);

  const renderChild = useCallback(
    () =>
      cloneElement(children, {
        className: cn(parentClassName, children?.props?.className || ''),
        ref: (node) => {
          refs.setReference(node);
        },
        onClick: () => {
          setIsOpen(!isOpen);
        },
      }),
    [children, isOpen, parentClassName, refs],
  );

  return (
    <>
      {renderChild()}
      {isOpen && (
        <Portal>
          <PopoverInner
            className={className}
            style={{
              ...floatingStyles,
              visibility: middlewareData.hide?.referenceHidden
                ? 'hidden'
                : 'visible',
            }}
            ref={(el) => {
              popperElementRef.current = el;
              refs.setFloating(el);
            }}
            onCloseClick={() => {
              setIsOpen(false);
            }}
            title={title}
            content={content}
          />
        </Portal>
      )}
    </>
  );
};

Popover.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.node,
  placement: PropTypes.oneOf([
    'auto',
    'top',
    'bottom',
    'right',
    'left',
    'top-start',
    'top-end',
    'bottom-start',
    'bottom-end',
    'right-start',
    'right-end',
    'left-start',
    'left-end',
  ]),
  title: PropTypes.string,
};

Popover.defaultProps = {
  children: undefined,
  className: undefined,
  content: undefined,
  placement: undefined,
  title: undefined,
};

export default Popover;
