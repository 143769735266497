import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePrevious } from 'react-use';

import Button from '../../../components/Button';
import DatePicker from '../../../components/DatePicker';
import Drawer from '../../../components/Drawer';
import Select from '../../../components/Select';
import ToursSearchBy from '../../../enums/ToursSearchBy';
import UserRole from '../../../enums/UserRole';
import parseSearchParams from '../../../helpers/parseSearchParams';
import useGroceriesToursPageSearchParams from '../../../hooks/useGroceriesToursPageSearchParams';
import { useIsRole, useUser } from '../../../providers/UserProvider';
import CarriersSelect from '../../shared/CarriersSelect';
import CustomDateRangeDropdown from '../../shared/CustomDateRangeDropdown/CustomDateRangeDropdown';
import HubSelect from '../../shared/HubSelect';
import ToursSearch from '../ToursSearch';
import TourStatusSelect from '../TourStatusSelect';

const GroceriesToursFiltersDrawer = (props) => {
  const { isOpen, onClose } = props;
  const { searchParams: currentSearchParams, setSearchParams } =
    useGroceriesToursPageSearchParams();
  const prevIsOpen = usePrevious(isOpen);
  const { t } = useTranslation();
  const [params, setParams] = useState(parseSearchParams(currentSearchParams));
  const isSubcarrierFitlerVisible = useIsRole([
    UserRole.Admin,
    UserRole.HubManager,
    UserRole.OperationsManager,
  ]);
  const { user } = useUser();

  const datepickerRef = useRef(null);

  const organisation = user?.carrier;

  useEffect(() => {
    if (isOpen && !prevIsOpen) {
      setParams(parseSearchParams(currentSearchParams));
    }
  }, [currentSearchParams, isOpen, prevIsOpen]);

  const onSelectChange = useCallback(
    (queryKey, queryValue) => {
      const newParams = { ...params };

      newParams.page = 1;
      if (!queryValue) {
        delete newParams[queryKey];
      } else {
        newParams[queryKey] = queryValue;
      }
      setParams(newParams);
    },
    [params],
  );

  const [searchInputKey, setSearchInputKey] = useState(new Date().toString());

  const shiftOptions = [1, 2, 3].map((shift) => ({
    value: String(shift),
    label: `${t('Shift')} ${shift}`,
  }));

  const customCalendarContainer = (calendarProps) => (
    <CustomDateRangeDropdown
      onTodayClick={() => {
        const newParams = { ...params };
        newParams.page = 1;
        newParams.startDate = moment().format('YYYY-MM-DD');
        newParams.endDate = moment().format('YYYY-MM-DD');
        setParams(newParams);
        datepickerRef?.current.setOpen(false);
      }}
      onTomorrowClick={() => {
        const newParams = { ...params };
        newParams.page = 1;
        newParams.startDate = moment().add(1, 'days').format('YYYY-MM-DD');
        newParams.endDate = moment().add(1, 'days').format('YYYY-MM-DD');
        setParams(newParams);
        datepickerRef?.current.setOpen(false);
      }}
      onYesterdayClick={() => {
        const newParams = { ...params };
        newParams.page = 1;
        newParams.startDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
        newParams.endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
        setParams(newParams);
        datepickerRef?.current.setOpen(false);
      }}
      onLastWeekClick={() => {
        const newParams = { ...params };
        newParams.page = 1;
        newParams.startDate = moment()
          .subtract(1, 'week')
          .startOf('week')
          .format('YYYY-MM-DD');
        newParams.endDate = moment()
          .subtract(1, 'week')
          .endOf('week')
          .format('YYYY-MM-DD');
        setParams(newParams);
        datepickerRef?.current.setOpen(false);
      }}
      onThisWeekClick={() => {
        const newParams = { ...params };
        newParams.page = 1;
        newParams.startDate = moment().startOf('week').format('YYYY-MM-DD');
        newParams.endDate = moment().endOf('week').format('YYYY-MM-DD');
        setParams(newParams);
        datepickerRef?.current.setOpen(false);
      }}
      {...calendarProps}
    />
  );

  return (
    <Drawer
      onClose={onClose}
      isOpen={isOpen}
      header={t('Filters')}
      footer={
        <Button
          data-test="modal-button-action"
          text={t('Apply')}
          variant="solidBlue"
          isFullWidth
          onClick={() => {
            const urlParams = new URLSearchParams(params);
            setSearchParams(urlParams);
            onClose();
          }}
        />
      }
      body={
        <div className="flex flex-col gap-4">
          <ToursSearch
            key={searchInputKey}
            debounceMs={0}
            searchBy={params.searchBy || ToursSearchBy.TourNumber}
            searchValue={params.search}
            onSearch={(newSearchValue) => {
              const newParams = { ...params };
              if (newSearchValue === params.search || !params.searchBy) {
                return;
              }
              newParams.page = 1;
              if (!newSearchValue) {
                delete newParams.search;
              } else {
                newParams.search = newSearchValue?.trim();
                newParams.searchBy = params.searchBy;
              }
              setParams(newParams);
            }}
            onSearchByChange={(newSearchBy) => {
              const newParams = { ...params };
              newParams.page = 1;
              newParams.searchBy = newSearchBy;
              setParams(newParams);
            }}
          />

          <div className="border-b border-grey-200" />

          <DatePicker
            dateFormat="dd.MM.’yy"
            isClearable
            name="date"
            placeholderText={t('Start date → End date')}
            required
            selectsRange
            startDate={params.startDate ? new Date(params.startDate) : null}
            endDate={params.endDate ? new Date(params.endDate) : null}
            onChange={([selectedStartDate, selectedEndDate]) => {
              const newParams = { ...params };
              newParams.page = 1;

              const formattedStartDate =
                selectedStartDate &&
                moment(selectedStartDate).format('YYYY-MM-DD');
              const formattedEndDate =
                selectedEndDate && moment(selectedEndDate).format('YYYY-MM-DD');

              if (selectedStartDate) {
                newParams.startDate = formattedStartDate;
                newParams.endDate = null;
                setParams(newParams);
              }

              if (selectedEndDate) {
                newParams.endDate = formattedEndDate;
                setParams(newParams);
                datepickerRef.current?.setOpen(false);
              }

              if (!selectedStartDate && !selectedEndDate) {
                delete newParams.startDate;
                delete newParams.endDate;
                setParams(newParams);
              }
            }}
            selected={params.startDate ? new Date(params.startDate) : null}
            minWidth={188}
            ref={datepickerRef}
            calendarContainer={customCalendarContainer}
            onCalendarClose={() => {
              const newParams = { ...params };
              if (newParams.startDate && !newParams.endDate) {
                setParams({
                  ...params,
                  endDate: newParams.startDate,
                });
              }
            }}
          />

          <Select
            className="lg:w-[160px]"
            placeholder={t('Shift')}
            options={shiftOptions}
            value={params.shiftNumber}
            onChange={(value) => {
              onSelectChange('shiftNumber', value);
            }}
          />

          <HubSelect
            value={params.hubId}
            onChange={(hubId) => {
              onSelectChange('hubId', hubId);
            }}
          />

          {isSubcarrierFitlerVisible && (
            <CarriersSelect
              prependOptions={[
                {
                  label: organisation.name,
                  value: 'null',
                },
              ]}
              carrierId={organisation.id}
              className="lg:w-[160px]"
              value={params.subcarrierId}
              onChange={(value) => {
                const newParams = { ...params };
                newParams.page = 1;
                if (!value) {
                  delete newParams.subcarrierId;
                } else if (value === organisation?.id) {
                  newParams.subcarrierId = 'null';
                } else {
                  newParams.subcarrierId = value;
                }

                setParams(newParams);
              }}
            />
          )}

          <TourStatusSelect
            value={params.tourStatus}
            onChange={(tourStatus) => {
              onSelectChange('tourStatus', tourStatus);
            }}
          />

          <Button
            isFullWidth
            variant="outlineBlue"
            text={t('Clear All')}
            onClick={() => {
              setSearchInputKey(new Date().toString());
              const newParams = {
                page: 1,
                searchBy: params.searchBy,
                search: '',
              };

              setParams(newParams);
            }}
          />
        </div>
      }
    />
  );
};

GroceriesToursFiltersDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

GroceriesToursFiltersDrawer.defaultProps = {
  isOpen: false,
  onClose: undefined,
};

export default GroceriesToursFiltersDrawer;
