import { useTranslation } from 'react-i18next';

import DeliveryTaskUnitStatus from '../../../../enums/DeliveryTaskUnitStatus';
import useDeliveryUnitStatusTranslations from '../../../../hooks/useDeliveryUnitStatusTranslations';
import eventType from '../eventType';
import ExecutionField from './ExecutionField';
import ExecutionTypeField from './ExecutionTypeField';
import FailureReasonField from './FailureReasonField';
import NoteField from './NoteField';
import ReturnReasonField from './ReturnReasonField';
import StatusField from './StatusField';
import UserField from './UserField';

const StatusChangeEvent = (props) => {
  const { event, previousEventWithStatus } = props;
  const { t } = useTranslation();

  const deliveryUnitStatusTranslations = useDeliveryUnitStatusTranslations();
  const previousStatus = previousEventWithStatus?.status;

  return (
    <>
      <StatusField event={event} />
      {event.status === DeliveryTaskUnitStatus.Delivered && (
        <ExecutionTypeField event={event} />
      )}
      {event.returnReason && <ReturnReasonField event={event} />}
      {event.failureReason && <FailureReasonField event={event} />}

      {event.changeNote && <NoteField note={event.changeNote} />}
      {previousStatus && previousStatus !== event.status && (
        <div>
          <span>{t('Previous status')}: </span>
          <span className="font-medium">
            {deliveryUnitStatusTranslations[previousStatus]}
          </span>
        </div>
      )}
      {event.autoExecutionSlotChange && <ExecutionField event={event} />}

      {event.endCustomerNoticeSent !== null &&
        event.endCustomerNoticeSent !== undefined && (
          <div>
            {t('Customer notice:')}{' '}
            <span className="font-medium">
              {event.endCustomerNoticeSent ? t('Sent') : t('Not sent')}
            </span>
          </div>
        )}

      <UserField event={event} />
    </>
  );
};

StatusChangeEvent.propTypes = {
  event: eventType.isRequired,
  previousEventWithStatus: eventType,
};

StatusChangeEvent.defaultProps = {
  previousEventWithStatus: undefined,
};

export default StatusChangeEvent;
