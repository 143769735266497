/* eslint-disable react/no-unstable-nested-components */
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import IconButton from '../../components/IconButton';
import NewTable, {
  CellAlignment,
  CellPadding,
} from '../../components/NewTable';
import Tooltip from '../../components/Tooltip';
import UserRole from '../../enums/UserRole';
import { useUser } from '../../providers/UserProvider';
import ShiftLabel from '../shared/ShiftLabel';
import GroceriesTourAssignment from './GroceriesTourAssignment';
import GroceriesTourDetails from './GroceryTourDetails';
import GroceriesToursFilters from './GroceryToursFilters';
import GroceryTourStatusInfo from './GroceryTourStatusInfo';

const GroceryToursTable = ({ total, ...props }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const userRole = user?.role;
  const isAssignmentCellVisible =
    userRole === UserRole.Admin ||
    userRole === UserRole.OperationsManager ||
    userRole === UserRole.HubManager;

  const columns = useMemo(() => {
    let tempColumns = [
      {
        accessorKey: 'vehicle',
        header: t('Tour ID'),
        size: 220,
        cell: (info) => (
          <span className="min-w-[100px] break-all">
            <Tooltip
              placement="bottom-start"
              text={info.row.original.tourNumber}
            >
              {info.getValue()}
            </Tooltip>
          </span>
        ),
      },
      {
        size: 250,
        header: t('Assigned to'),
        key: 'assignment',
        cell: (info) => <GroceriesTourAssignment tour={info.row.original} />,
        meta: {
          paddingY: CellPadding.Sm,
        },
      },
      {
        size: 220,
        header: t('Status'),
        accessorKey: 'status',
        cell: (info) => (
          <GroceryTourStatusInfo tourStatus={info.row.original.status} />
        ),
      },
      {
        size: 250,
        header: t('Tour Details'),
        cell: (info) => <GroceriesTourDetails tour={info.row.original} />,
      },
      {
        header: t('Date'),
        accessorKey: 'date',
        cell: (info) => moment(info.getValue()).format('DD.MM.YYYY'),
      },
      {
        header: t('Start'),
        cell: (info) => {
          const shiftNumber = info.row.original.shift;

          return (
            <span className="flex items-center gap-1">
              <div>
                <ShiftLabel shiftNumber={shiftNumber} />
              </div>
              <span className="lg:hidden xl:inline">
                {moment(info.row.original.startLoading).format('HH:mm')}
              </span>
            </span>
          );
        },
      },
      {
        id: 'actions',
        cell: (info) => (
          <Tooltip
            className="flex"
            placement="top"
            size="sm"
            text={t('Member Details')}
          >
            <IconButton
              size="xs"
              as={Link}
              icon="pageView"
              state={{
                backLink: -1,
              }}
              to={{
                pathname: `/grocery-tours/${info.row.original.id}`,
              }}
              variant="ghostBlack"
            />
          </Tooltip>
        ),
        meta: {
          align: CellAlignment.Center,
        },
        size: 50,
        enableResizing: false,
      },
    ];

    if (!isAssignmentCellVisible) {
      tempColumns = tempColumns.filter((column) => column.key !== 'assignment');
    }

    return tempColumns;
  }, [isAssignmentCellVisible, t]);

  return (
    <>
      <GroceriesToursFilters count={total} />
      <NewTable
        id="grocery-tours-table"
        restoreScroll
        inlineScroll
        minColSize={50}
        columns={columns}
        {...props}
      />
    </>
  );
};

GroceryToursTable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  total: PropTypes.number,
};

GroceryToursTable.defaultProps = {
  children: undefined,
  className: '',
  total: undefined,
};

export default GroceryToursTable;
