import { useTranslation } from 'react-i18next';

import useDeliveryOptionsTranslations from '../../../../hooks/useDeliveryOptionsTranslations';
import eventType from '../eventType';
import ExecutionField from './ExecutionField';
import NoteField from './NoteField';
import StatusField from './StatusField';
import UserField from './UserField';

const ImportEvent = (props) => {
  const { event } = props;

  const { t } = useTranslation();

  const { nameTranslations } = useDeliveryOptionsTranslations();
  const note = event.deliveryInstructions
    ?.map(
      (deliveryInstruction) =>
        `${
          nameTranslations[deliveryInstruction.deliveryOption]
        }: ${deliveryInstruction.instruction}`,
    )
    .join(', ');

  return (
    <>
      <StatusField event={event} />
      <ExecutionField event={event} />
      {event.deliveryOptionsConsent?.length > 0 && (
        <div>
          <span>{t('Delivery consent provided')}: </span>
          <span className="font-medium">
            {event.deliveryOptionsConsent.map(
              (option, index) =>
                `${nameTranslations[option]}${
                  index < event.deliveryOptionsConsent.length - 1 ? ', ' : ''
                }`,
            )}
          </span>
        </div>
      )}
      {note && <NoteField note={note} />}
      <UserField event={event} />
    </>
  );
};

ImportEvent.propTypes = {
  event: eventType.isRequired,
};

export default ImportEvent;
