import moment from 'moment';
import { useTranslation } from 'react-i18next';

import eventType from '../eventType';

const ExecutionField = (props) => {
  const { event } = props;
  const { t } = useTranslation();

  return (
    event.timeFrom &&
    event.timeTo &&
    moment(event.timeFrom).isSame(event.timeTo, 'day') && (
      <div>
        <span>
          {event.autoExecutionSlotChange
            ? t('Execution date and time updated')
            : t('Execution')}
          :{' '}
        </span>
        <span className="font-medium">
          {moment(event.timeFrom).format('DD.MM.YYYY')}
          {' - '}
          {event.shiftNumber && `S${event.shiftNumber} `}(
          {moment(event.timeFrom).format('HH:mm')}-
          {moment(event.timeTo).format('HH:mm')})
        </span>
      </div>
    )
  );
};

ExecutionField.propTypes = {
  event: eventType.isRequired,
};

export default ExecutionField;
