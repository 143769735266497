import {
  autoUpdate,
  flip,
  hide,
  offset,
  shift,
  size as sizeMiddleware,
  useFloating,
} from '@floating-ui/react-dom';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import CSSTransitionStage from '../../enums/CSSTransitionStage';
import useCSSTransition from '../../hooks/useCSSTransition';
import { useFloatingContainer } from '../../providers/FloatingContainerProvider';

const padding = 10;

const Tooltip = ({
  children,
  className,
  defaultVisible,
  hidden,
  onMouseLeave: propOnMouseLeave,
  placement,
  size,
  text,
  ...rest
}) => {
  const [isVisible, setIsVisible] = useState(defaultVisible);

  const { floatingContainer } = useFloatingContainer();

  const { floatingStyles, middlewareData, refs } = useFloating({
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [
      hide({
        boundary: floatingContainer,
      }),
      offset({ mainAxis: 6 }),
      shift({
        padding,
      }),
      sizeMiddleware({
        apply: ({ availableWidth, elements }) => {
          const scrollbar = window.innerWidth - document.body.clientWidth;
          const maxWidth = availableWidth - scrollbar - padding * 2;

          if (maxWidth <= 400) {
            // eslint-disable-next-line no-param-reassign
            elements.floating.style.maxWidth = `${maxWidth}px`;
          }
        },
      }),
      flip(),
    ],
  });

  const timeoutRef = useRef(null);

  useEffect(
    () => () => {
      clearTimeout(timeoutRef.current);
    },
    [],
  );

  const onMouseEnter = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      setIsVisible(true);
    }, 50);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsVisible(false);
    clearTimeout(timeoutRef.current);
    propOnMouseLeave();
  }, [propOnMouseLeave]);

  const { shouldMount, stage } = useCSSTransition(isVisible, 150);

  return (
    <>
      <span
        className={className}
        ref={refs.setReference}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </span>
      {!hidden &&
        shouldMount &&
        ReactDOM.createPortal(
          <div className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 z-30 floating">
            <div
              className={cn(
                'pointer-events-none rounded-md bg-grey-900 text-center text-white shadow-elevation-300 transition-[opacity] max-w-[400px] relative z-0',
                stage === CSSTransitionStage.To && 'opacity-90',
                stage === CSSTransitionStage.From && 'opacity-0',
                size === 'sm' && 'px-2 py-1 text-xs',
                size === 'md' && 'px-2 py-[6px] text-sm',
              )}
              ref={refs.setFloating}
              style={{
                ...floatingStyles,
                visibility: middlewareData.hide?.referenceHidden
                  ? 'hidden'
                  : 'visible',
              }}
              {...rest}
            >
              <div className="z-0 relative">{text}</div>
            </div>
          </div>,
          document.body,
        )}
    </>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
  ]),
  size: PropTypes.oneOf(['sm', 'md']),
  text: PropTypes.node.isRequired,
  defaultVisible: PropTypes.bool,
  onMouseLeave: PropTypes.func,
};

Tooltip.defaultProps = {
  className: '',
  hidden: false,
  placement: 'top',
  size: 'md',
  defaultVisible: false,
  onMouseLeave: () => {},
};

export default Tooltip;
