import moment from 'moment';
import { Navigate } from 'react-router-dom';

import UserRole from '../enums/UserRole';
import { useUser } from '../providers/UserProvider';

const RootRedirect = () => {
  const { isGroceries, user } = useUser();
  const todayFormatted = moment().format('YYYY-MM-DD');

  if (user.role === UserRole.Crew) {
    return <Navigate replace to="/package-scanning" />;
  }

  if (user.role === UserRole.Superadmin) {
    return <Navigate replace to="/organisations" />;
  }

  if (isGroceries) {
    return (
      <Navigate
        replace
        to={`/grocery-tours?page=1&startDate=${todayFormatted}&endDate=${todayFormatted}`}
      />
    );
  }

  return (
    <Navigate
      replace
      to={`/tours/fixed?page=1&startDate=${todayFormatted}&endDate=${todayFormatted}`}
    />
  );
};

export default RootRedirect;
