import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Collapse from '../../../components/Collapse';
import Icon from '../../../components/Icon';
import { useListSelection } from '../../../providers/ListSelectionProvider';

const BulkEditUnitList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { selectedItems } = useListSelection();
  const { t } = useTranslation();

  return (
    <div>
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="text-sm flex gap-2">
          <div className="text-grey-700 flex items-center gap-2">
            <Icon className="w-5 h-5" icon="parcel" />
            <span className="">{t('Units selected')}:</span>
          </div>
          <span>{selectedItems.length}</span>
        </div>
        <Icon
          className="w-3 h-3 text-primary-dark"
          icon={isOpen ? 'chevronUp' : 'chevronDown'}
        />
      </div>
      <Collapse isOpen={isOpen}>
        <div className="flex flex-col gap-4 mt-6 text-sm">
          {selectedItems.map((item) => (
            <div
              key={item.id}
              className="flex flex-row justify-between items-center"
            >
              <span className="font-medium">{item.code}</span>
              <span>{item.client?.publicName}</span>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default BulkEditUnitList;
