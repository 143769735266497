import { keepPreviousData, useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BadgeIndicator from '../../../components/BadgeIndicator';
import IconButton from '../../../components/IconButton';
import Page from '../../../components/Page';
import Titlebar from '../../../components/Titlebar';
import UserRole from '../../../enums/UserRole';
import useGroceriesToursPageSearchParams from '../../../hooks/useGroceriesToursPageSearchParams';
import useIsStuck from '../../../hooks/useIsStuck';
import useResourceQuery from '../../../lib/api/hooks/useResourceQuery';
import Resource from '../../../lib/api/Resource';
import Allow from '../../../lib/rbac/Allow';
import FloatingContainerProvider from '../../../providers/FloatingContainerProvider';
import { useTitlebarHeight } from '../../../providers/TitlebarHeightProvider';
import MobileListVisibleContent from '../../shared/MobileListVisibleContent';
import GroceriesDataExportModal from '../DataExportModal';
import GroceriesToursFiltersDrawer from '../GroceriesToursFitlersDrawer';
import GroceryTourCardList from '../GroceryTourCardList';

const itemsPerPage = 15;

const GroceriesToursPageMobile = () => {
  const { t } = useTranslation();
  const { totalTitlebarHeight } = useTitlebarHeight();
  const stickyElementRef = useRef(null);
  const isStuck = useIsStuck(stickyElementRef);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const visiblePageContentRef = useRef(null);
  const { allParams: params, isFilterApplied } =
    useGroceriesToursPageSearchParams();
  const [isDataExportModalOpen, setIsDataExportModalOpen] = useState(false);

  const { queryKey } = useResourceQuery({
    itemsPerPage,
    resourceUrl: '/grocery-tours',
    params,
  });

  const { data } = useQuery({ queryKey, placeholderData: keepPreviousData });

  return (
    <Page>
      <GroceriesDataExportModal
        isOpen={isDataExportModalOpen}
        onClose={() => setIsDataExportModalOpen(false)}
      />
      <GroceriesToursFiltersDrawer
        onClose={() => setIsFilterDrawerOpen(false)}
        isOpen={isFilterDrawerOpen}
      />
      <Titlebar
        textPrimary={t('Tours')}
        titleRightContent={
          <Allow roles={[UserRole.Admin, UserRole.OperationsManager]}>
            <IconButton
              onClick={() => setIsDataExportModalOpen(true)}
              iconSize="s"
              icon="dataExport"
              variant="outlineBlack"
            />
          </Allow>
        }
        showTitleRightContentInCollapse={false}
      />
      <div
        ref={stickyElementRef}
        className={cn(
          'bg-white px-4 pb-2 sticky z-10 border-b transition-[padding]',
          isStuck ? 'border-grey-200 pt-3' : 'border-transparent pt-2',
        )}
        style={{
          top: `calc(var(--topbar-height) + ${totalTitlebarHeight}px)`,
        }}
      >
        <div className="flex gap-2 justify-between">
          <div className="flex flex-col grow overflow-hidden">
            <div className="text-sm pt-3">
              <span className="text-grey-700">{t('Tour count')}:</span>{' '}
              <span className="font-medium">{data?.count || 0}</span>
            </div>
          </div>
          <div className="flex gap-2">
            <BadgeIndicator variant="warning" isVisible={isFilterApplied}>
              <IconButton
                onClick={() => setIsFilterDrawerOpen(true)}
                iconSize="s"
                icon="filter"
                variant="outlineBlack"
              />
            </BadgeIndicator>
          </div>
        </div>
      </div>
      <Page.Content variant="grey">
        <FloatingContainerProvider
          floatingContainer={visiblePageContentRef?.current}
        >
          <Resource
            params={params}
            itemsPerPage={itemsPerPage}
            showPageSizePicker={false}
            resourceUrl="/grocery-tours"
            socketEntityName="groceryTour"
          >
            <GroceryTourCardList />
          </Resource>
          <MobileListVisibleContent
            ref={visiblePageContentRef}
            stickyElementRef={stickyElementRef}
          />
        </FloatingContainerProvider>
      </Page.Content>
    </Page>
  );
};

export default GroceriesToursPageMobile;
