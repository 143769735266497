import { useTranslation } from 'react-i18next';

import eventType from '../eventType';
import ExecutionField from './ExecutionField';
import StatusField from './StatusField';
import UserField from './UserField';

const HubScanEvent = (props) => {
  const { event } = props;
  const { t } = useTranslation();
  return (
    <>
      <StatusField event={event} />
      {event.hub && (
        <div>
          <span>{t('Hub')}: </span>
          <span className="font-medium">
            {event.hub.name} {event.hub.shortCode && `(${event.hub.shortCode})`}
          </span>
        </div>
      )}

      {event.autoExecutionSlotChange && <ExecutionField event={event} />}

      <UserField event={event} />
    </>
  );
};

HubScanEvent.propTypes = {
  event: eventType.isRequired,
};

export default HubScanEvent;
