import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import useBorderBoxSize from '../../hooks/useBorderBoxSize';
import FadingOverlay from '../FadingOverlay';
import Icon from '../Icon';
import ListSelectionCheckbox from '../ListSelectionCheckbox';
import ElementScrollRestoration from '../ScrollRestoration';
import StickyLoader from '../StickyLoader';
import ColumnVisibilityDrawer from './ColumnVisibilityDrawer';
import SelectAllRowsCheckbox from './SelectAllRowsCheckbox';
import Shadow from './Shadow';
import useCalculateTableScroll from './useCalculateTableScroll';

export const CellPadding = {
  Sm: 'sm',
  Md: 'md',
};

export const CellAlignment = {
  Left: 'left',
  Right: 'right',
  Center: 'center',
};

const cellPaddingXMap = {
  [CellPadding.Sm]: 'px-2',
  [CellPadding.Md]: 'px-4',
};

const cellPaddingYMap = {
  [CellPadding.Sm]: 'py-1.5',
  [CellPadding.Md]: 'py-2.5',
};

const headerCellPaddingXMap = {
  [CellPadding.Sm]: 'px-2',
  [CellPadding.Md]: 'px-4',
};

const headerCellPaddingYMap = {
  [CellPadding.Sm]: 'py-0.5',
  [CellPadding.Md]: 'py-2.5',
};

const cellAlignmentMap = {
  [CellAlignment.Left]: 'justify-start',
  [CellAlignment.Right]: 'justify-end',
  [CellAlignment.Center]: 'justify-center',
};

const tableSelectionColumnId = 'table_selection_col';
const NewTable = ({
  columnVisibilityState,
  columns: propColumns,
  data,
  'data-test': dataTest,
  enableSelection,
  id,
  inlineScroll,
  isDrawerOpen,
  isLoading,
  isPending,
  minColSize,
  noRecordsText,
  onColumnVisibilityChange,
  onDrawerClose,
  preserveExpandedStateInLocation,
  renderSubComponent,
  restoreScroll,
  roundedTop,
  skeletonRowHeight,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const locationTableState = useMemo(() => {
    if (!preserveExpandedStateInLocation) {
      return undefined;
    }
    return location?.state?.[id] || undefined;
  }, [id, location?.state, preserveExpandedStateInLocation]);
  const [searchParams] = useSearchParams();
  const locationExpandAll =
    searchParams.get('expandAll') === 'true' && preserveExpandedStateInLocation;

  const localTableRef = useRef(null);

  const scrollRestorationEnabled = restoreScroll && id !== undefined;

  if (import.meta.env.MODE === 'development') {
    // rules of hooks can be avoided here
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (restoreScroll && id === undefined) {
        // eslint-disable-next-line no-console
        console.error('restoreScroll is enabled but no id is passed!');
      }
    }, [id, restoreScroll]);
  }

  useEffect(() => {
    // if scroll restoration is not enabled, always reset scroll on location change
    if (location && !scrollRestorationEnabled) {
      // scroll to top on location change
      if (localTableRef?.current) {
        localTableRef.current.scrollTop = 0;
      }
    }
  }, [location, localTableRef, scrollRestorationEnabled]);

  const columns = useMemo(
    () =>
      enableSelection || !!renderSubComponent
        ? [
            {
              id: tableSelectionColumnId,
              size: 40,
              minSize: 40,
              enableHiding: false,
              meta: {
                showInVisibilityToggle: false,
                expandable: true,
              },
            },
            ...propColumns,
          ]
        : propColumns,
    [enableSelection, propColumns, renderSubComponent],
  );

  const table = useReactTable({
    getRowId: (originalRow) => originalRow.id,
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: minColSize,
    },
    initialState: {
      // for initial data load based on location state and expandAll param
      ...(locationTableState?.expanded && {
        expanded: locationTableState.expanded,
      }),
      ...(locationExpandAll && {
        expanded: data.reduce(
          (acc, row) => ({
            ...acc,
            [row.id]: true,
          }),
          {},
        ),
      }),
    },
    state: {
      ...(columnVisibilityState && {
        columnVisibility: columnVisibilityState,
      }),
    },
  });
  const navigate = useNavigate();
  const updateLocationWithTableState = useCallback(
    (newState, expandAll) => {
      if (!id) {
        // eslint-disable-next-line no-console
        console.error('id is required to update location with table state');
      }
      const { search, ...rest } = location;
      const newSearchParams = new URLSearchParams(search);
      if (expandAll) {
        newSearchParams.set('expandAll', 'true');
      } else {
        newSearchParams.delete('expandAll');
      }
      const newLocation = {
        ...rest,
        search: `?${newSearchParams.toString()}`,
      };

      navigate(newLocation, {
        replace: true,
        state: {
          [id]: newState,
        },
      });
    },
    [id, location, navigate],
  );

  const allTableColumns = table
    .getAllColumns()
    .filter((col) => col.getIsVisible());
  const totalTableWidth = table.getTotalSize();
  const [wrapperSizingRef, wrapperBorderBoxSize] = useBorderBoxSize();
  const wrapperWidth = wrapperBorderBoxSize.inlineSize;

  const scrollbarWidthCssVariable = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue('--scrollbar-width');
  const scrollbarWidth = useMemo(() => {
    const val = parseInt(scrollbarWidthCssVariable, 10);
    if (!Number.isNaN(val)) {
      return val;
    }
    return 0;
  }, [scrollbarWidthCssVariable]);
  // vertical scroll bar is intentionally set by "overflow-y-scroll" to indicate table as a scroll container
  const wrapperWidthWithoutScrollbar = wrapperWidth - scrollbarWidth;
  const missingWidth = useMemo(() => {
    if (inlineScroll) {
      return wrapperWidthWithoutScrollbar > totalTableWidth
        ? // -1 is for the border
          wrapperWidthWithoutScrollbar - totalTableWidth - 1
        : 0;
    }

    return wrapperWidth > totalTableWidth
      ? // -1 is for the border
        wrapperWidth - totalTableWidth - 1
      : 0;
  }, [
    inlineScroll,
    totalTableWidth,
    wrapperWidth,
    wrapperWidthWithoutScrollbar,
  ]);
  const adjustedWidth = totalTableWidth + missingWidth;
  const isOverflown = inlineScroll
    ? adjustedWidth + scrollbarWidth > wrapperWidth
    : adjustedWidth > wrapperWidth;

  const { rows } = table.getRowModel();
  const expandAllRowsOnCurrentPage = useCallback(() => {
    table.setExpanded(() =>
      rows.reduce(
        (acc, row) => ({
          ...acc,
          [row.id]: true,
        }),
        {},
      ),
    );
  }, [rows, table]);

  const collapseAllRowsOnCurrentPage = useCallback(() => {
    table.setExpanded(() => ({}));
  }, [table]);

  useLayoutEffect(() => {
    if (locationExpandAll) {
      expandAllRowsOnCurrentPage();
    } else if (locationTableState?.expanded) {
      table.setExpanded(() => locationTableState?.expanded || {});
    }
  }, [
    expandAllRowsOnCurrentPage,
    locationExpandAll,
    locationTableState,
    table,
  ]);

  const isSomeRowsExpandedOnCurrentPage = rows.some((row) =>
    row.getIsExpanded(),
  );

  useLayoutEffect(() => {
    if (enableSelection && isSomeRowsExpandedOnCurrentPage) {
      collapseAllRowsOnCurrentPage();
      if (preserveExpandedStateInLocation) {
        updateLocationWithTableState({ expanded: {} }, false);
      }
    }
  }, [
    collapseAllRowsOnCurrentPage,
    enableSelection,
    isSomeRowsExpandedOnCurrentPage,
    location,
    navigate,
    preserveExpandedStateInLocation,
    updateLocationWithTableState,
  ]);

  // minus 2 columns because first and last column will be fixed on first render
  const colExtraSize = Math.floor(missingWidth / (allTableColumns.length - 2));

  const hasData = table.getRowModel().rows.length > 0;
  const colSizes = allTableColumns.reduce((acc, col, currentIndex) => {
    const currentColInitialSize = col.getSize();

    if (currentIndex === allTableColumns.length - 2) {
      const finalTotalSize = Object.values(acc).reduce(
        (totalSize, size) => totalSize + size,
        currentColInitialSize,
      );

      const missingPxToFullWidth =
        totalTableWidth +
        missingWidth -
        finalTotalSize -
        allTableColumns[allTableColumns.length - 1].getSize();

      return {
        ...acc,
        [col.id]: currentColInitialSize + missingPxToFullWidth,
      };
    }

    if (currentIndex === 0 || currentIndex === allTableColumns.length - 1) {
      return { ...acc, [col.id]: currentColInitialSize };
    }

    return { ...acc, [col.id]: currentColInitialSize + colExtraSize };
  }, {});

  const getStickyColumnLeft = (col) => {
    if (col.id === allTableColumns[0].id) {
      return 0;
    }

    if (enableSelection && col.id === allTableColumns[1].id) {
      return colSizes[tableSelectionColumnId];
    }

    return undefined;
  };

  const isStickyColumn = useCallback(
    (column) => {
      if (column.id === allTableColumns[0].id) {
        return true;
      }

      if (enableSelection && column.id === allTableColumns[1].id) {
        return true;
      }

      if (
        !enableSelection &&
        column.id === allTableColumns[allTableColumns.length - 1].id
      ) {
        return true;
      }

      return false;
    },
    [allTableColumns, enableSelection],
  );

  const getTotalLeftStickyWidth = () =>
    allTableColumns.reduce((acc, col, currentIndex) => {
      // ignore last column as it is sticky to right
      if (currentIndex === allTableColumns.length - 1) {
        return acc;
      }
      if (isStickyColumn(col)) {
        return acc + colSizes[col.id];
      }
      return acc;
    }, 0);

  const getCellBorder = useCallback(
    (index) => {
      if (index === 0) {
        return 'border-r';
      }

      if (!enableSelection && index > 1) {
        return 'border-l';
      }

      if (enableSelection) {
        if (index > 2) {
          return 'border-l';
        }
        if (index === 1 || index === 0) {
          return 'border-r';
        }
      }

      return undefined;
    },
    [enableSelection],
  );

  const [headerRef, headerSize] = useBorderBoxSize();
  const { isTableStickedLeft, isTableStickedRight, tableRef } =
    // wrapper size is used as a "dependency array"
    useCalculateTableScroll(wrapperBorderBoxSize.inlineSize);

  const totalLeftStickyWidth = getTotalLeftStickyWidth();
  // 1px offset for border right on table wrapper
  const totalRightStickyWidth =
    colSizes[allTableColumns[allTableColumns.length - 1].id] + 1;

  const headerGroups = table.getHeaderGroups();
  const onExpandableThClick = useCallback(() => {
    if (preserveExpandedStateInLocation) {
      if (isSomeRowsExpandedOnCurrentPage) {
        collapseAllRowsOnCurrentPage();
        updateLocationWithTableState(
          {
            expanded: {},
          },
          false,
        );
      } else {
        const isAllRowsExpanded = table.getIsAllRowsExpanded();

        if (isAllRowsExpanded) {
          collapseAllRowsOnCurrentPage();
          updateLocationWithTableState(
            {
              expanded: {},
            },
            false,
          );
        } else {
          expandAllRowsOnCurrentPage();
          updateLocationWithTableState({}, true);
        }
      }
      return;
    }

    if (isSomeRowsExpandedOnCurrentPage) {
      table.toggleAllRowsExpanded(false);
    } else {
      table.toggleAllRowsExpanded(true);
    }
  }, [
    collapseAllRowsOnCurrentPage,
    expandAllRowsOnCurrentPage,
    isSomeRowsExpandedOnCurrentPage,
    preserveExpandedStateInLocation,
    table,
    updateLocationWithTableState,
  ]);

  return (
    <>
      {columnVisibilityState && (
        <ColumnVisibilityDrawer
          table={table}
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
          currentColumnVisibilityState={columnVisibilityState}
          onSave={onColumnVisibilityChange}
        />
      )}
      <div
        className={cn(
          'relative',
          inlineScroll && 'flex-1 flex flex-col overflow-hidden min-h-0',
        )}
        ref={wrapperSizingRef}
      >
        {(isPending || wrapperWidth === 0) && (
          <div className="relative leading-none">
            <Skeleton height={40} />
            <div className="mt-4">
              <FadingOverlay>
                <Skeleton
                  containerClassName="flex flex-col gap-4 z-0 relative"
                  height={skeletonRowHeight}
                  count={6}
                  inline
                />
              </FadingOverlay>
            </div>
          </div>
        )}
        <div
          className={cn(
            'border-b border-r border-grey-200',
            inlineScroll ? 'overflow-y-scroll' : 'overflow-auto',
            // initial load or just mounted, prevent visible horizontal upscaling of table cells
            (isPending || wrapperWidth === 0) && 'opacity-0 invisible',
          )}
          ref={(el) => {
            localTableRef.current = el;
            tableRef.current = el;
          }}
          id={id}
        >
          {isOverflown && (
            <Shadow
              className="z-[3]"
              style={{ height: headerSize?.blockSize || 0 }}
              totalLeftStickyWidth={totalLeftStickyWidth}
              totalRightStickyWidth={totalRightStickyWidth}
              isTableStickedLeft={isTableStickedLeft}
              isTableStickedRight={isTableStickedRight}
              inlineScroll={inlineScroll}
              hideRightShadow={enableSelection}
            />
          )}
          <table
            data-test={dataTest}
            className="relative block"
            style={{ width: adjustedWidth }}
          >
            <thead className="sticky top-0 z-[2]" ref={headerRef}>
              {headerGroups?.map((headerGroup) => (
                <tr
                  className="flex w-fit text-xs text-grey-700"
                  key={headerGroup.id}
                >
                  {headerGroup?.headers.map((header, headerIndex) => {
                    const isStickyHeader = isStickyColumn(header.column);
                    const isLastColumn =
                      header.column.id ===
                      allTableColumns[allTableColumns.length - 1].id;
                    const isExpandable =
                      header.column.columnDef.meta?.expandable &&
                      !enableSelection;
                    const cellPaddingX =
                      header.column.columnDef.meta?.paddingX || CellPadding.Md;
                    const cellPaddingY =
                      header.column.columnDef.meta?.paddingY || CellPadding.Md;
                    const headerClassName =
                      header.column.columnDef.meta?.header?.className;
                    const alignment =
                      header.column.columnDef.meta?.align || CellAlignment.Left;

                    // fake th element
                    const onFirstThClick = () => {
                      if (isExpandable) {
                        onExpandableThClick();
                      }
                    };
                    return (
                      <th
                        onClick={onFirstThClick}
                        className={cn(
                          'bg-grey-100 group font-medium last:border-l border-grey-300 flex items-center',
                          roundedTop &&
                            'last:rounded-tr-md first:rounded-tl-md',
                          getCellBorder(headerIndex),
                          isExpandable && 'cursor-pointer',
                          isStickyHeader ? 'z-[1]' : 'z-0',
                          header.column.id === tableSelectionColumnId
                            ? 'px-2'
                            : `${headerCellPaddingXMap[cellPaddingX]} ${headerCellPaddingYMap[cellPaddingY]}`,
                          headerClassName,
                        )}
                        style={{
                          width: colSizes[header.column.id],
                          position: isStickyHeader ? 'sticky' : 'relative',
                          left: getStickyColumnLeft(header.column),
                          right: isLastColumn && isStickyHeader ? 0 : undefined,
                        }}
                        key={header.id}
                      >
                        {header.column.id === tableSelectionColumnId && (
                          <div className="flex flex-1 justify-center">
                            {enableSelection && (
                              <SelectAllRowsCheckbox data={data} />
                            )}
                            {!enableSelection && isExpandable && (
                              <div className="flex items-center justify-center bg-white w-4 h-4 rounded-[3px] border border-grey-500">
                                <Icon
                                  className="h-2.5 w-2.5 text-grey-700"
                                  icon={
                                    isSomeRowsExpandedOnCurrentPage
                                      ? 'minus'
                                      : 'plus'
                                  }
                                />
                              </div>
                            )}
                          </div>
                        )}
                        {header.column.id === tableSelectionColumnId ||
                        header.isPlaceholder ? null : (
                          <div
                            className={cn(
                              'flex items-center w-full',
                              cellAlignmentMap[alignment],
                            )}
                          >
                            <span className="truncate">
                              {flexRender(
                                header?.column?.columnDef?.header,
                                header?.getContext(),
                              )}
                            </span>
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>

            <tbody className="text-sm text-primary-dark relative">
              {!hasData && (
                <tr
                  className="mx-0 flex min-h-[80px] w-full flex-col items-center justify-center px-6 py-10 border-grey-200 border-l sticky left-0"
                  style={{
                    width: inlineScroll
                      ? wrapperWidthWithoutScrollbar - 1
                      : wrapperWidth - 1,
                  }}
                >
                  <td className="flex flex-col items-center justify-center">
                    {isLoading ? null : (
                      <>
                        <Icon
                          className="h-10 w-10 text-grey-300"
                          icon="inbox"
                        />
                        <span className="mt-4 text-sm">
                          {noRecordsText || t('No records')}
                        </span>
                      </>
                    )}
                  </td>
                </tr>
              )}
              {hasData &&
                rows.map((row, rowIndex) => (
                  <Fragment key={row.id}>
                    <tr className="flex w-fit" key={row.id} data-test={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        const colId = cell.column.id;
                        const colIndex = allTableColumns.findIndex(
                          (col) => col.id === colId,
                        );

                        const isExpandable =
                          cell.column.columnDef.meta?.expandable &&
                          !enableSelection;
                        const cellPaddingX =
                          cell.column.columnDef.meta?.paddingX ||
                          CellPadding.Md;
                        const cellPaddingY =
                          cell.column.columnDef.meta?.paddingY ||
                          CellPadding.Md;
                        const alignment =
                          cell.column.columnDef.meta?.align ||
                          CellAlignment.Left;

                        const isLastColumn =
                          colId ===
                          allTableColumns[allTableColumns.length - 1].id;

                        const stickyColumn = isStickyColumn(cell.column);

                        return (
                          <td
                            onClick={() => {
                              if (isExpandable) {
                                const isExpanded = row.getIsExpanded();
                                row.toggleExpanded(!isExpanded);

                                if (preserveExpandedStateInLocation) {
                                  const newExpanded = {
                                    ...table.getState().expanded,
                                    [row.id]: !isExpanded,
                                  };
                                  updateLocationWithTableState(
                                    { expanded: newExpanded },
                                    false,
                                  );
                                }
                              }
                            }}
                            className={cn(
                              'flex items-center first:border-x last:border-l border-grey-200 break-all',
                              row.getIsExpanded() && 'bg-ui-yellow-light',
                              !row.getIsExpanded() && 'bg-white',
                              colId === tableSelectionColumnId
                                ? 'px-2'
                                : `${cellPaddingXMap[cellPaddingX]} ${cellPaddingYMap[cellPaddingY]}`,
                              getCellBorder(colIndex),
                              isExpandable && 'cursor-pointer',
                              stickyColumn ? 'z-[1]' : 'z-auto',
                              rows.length - 1 !== rowIndex &&
                                !row.getIsExpanded() &&
                                'border-b',
                              cellAlignmentMap[alignment],
                            )}
                            style={{
                              width: colSizes[colId],
                              position: stickyColumn ? 'sticky' : 'relative',
                              left: getStickyColumnLeft(cell.column),
                              right:
                                isLastColumn && stickyColumn ? 0 : undefined,
                            }}
                            key={cell.id}
                          >
                            {cell.column.id === tableSelectionColumnId ? (
                              <div className="flex justify-center flex-1">
                                {enableSelection && (
                                  <ListSelectionCheckbox
                                    record={cell.row.original}
                                  />
                                )}
                                {!enableSelection && isExpandable && (
                                  <Icon
                                    className="h-2.5 w-2.5"
                                    icon={
                                      row.getIsExpanded()
                                        ? 'chevronUp'
                                        : 'chevronRight'
                                    }
                                  />
                                )}
                              </div>
                            ) : (
                              flexRender(
                                cell.column?.columnDef?.cell,
                                cell?.getContext(),
                              )
                            )}
                          </td>
                        );
                      })}
                    </tr>
                    {renderSubComponent && row.getIsExpanded() && (
                      <tr
                        className={cn(
                          'left-0 block sticky text-center border-l border-b border-grey-200 bg-ui-yellow-light px-8 pt-2 pb-3 z-[1]',
                          // 1px offset for border right on table wrapper
                          inlineScroll
                            ? 'max-w-[calc(var(--page-content-width)_-_var(--scrollbar-width)_-_1px)]'
                            : 'max-w-[calc(var(--page-content-width))]',
                        )}
                      >
                        {renderSubComponent(row)}
                      </tr>
                    )}
                  </Fragment>
                ))}
            </tbody>
          </table>

          {isOverflown && hasData && (
            <Shadow
              style={{
                height: `calc(${
                  wrapperBorderBoxSize?.blockSize || 0
                  // small offset for scrollbar
                }px - var(--scrollbar-width))`,
              }}
              totalLeftStickyWidth={totalLeftStickyWidth}
              totalRightStickyWidth={totalRightStickyWidth}
              isTableStickedLeft={isTableStickedLeft}
              isTableStickedRight={isTableStickedRight}
              inlineScroll={inlineScroll}
              hideRightShadow={enableSelection}
            />
          )}

          {!isPending && <StickyLoader isLoading={isLoading} />}
        </div>
      </div>

      {scrollRestorationEnabled && (
        <ElementScrollRestoration vertical horizontal elementQuery={`#${id}`} />
      )}
    </>
  );
};

NewTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessorKey: PropTypes.string,
      header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      cell: PropTypes.func,
      size: PropTypes.number,
    }),
  ),
  renderSubComponent: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isPending: PropTypes.bool,
  isLoading: PropTypes.bool,
  skeletonRowHeight: PropTypes.number,
  'data-test': PropTypes.string,
  noRecordsText: PropTypes.string,
  isDrawerOpen: PropTypes.bool,
  onDrawerClose: PropTypes.func,
  enableSelection: PropTypes.bool,
  onColumnVisibilityChange: PropTypes.func,
  columnVisibilityState: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      [PropTypes.string]: PropTypes.bool,
    }),
  }),
  roundedTop: PropTypes.bool,
  minColSize: PropTypes.number,
  inlineScroll: PropTypes.bool,
  id: PropTypes.string,
  restoreScroll: PropTypes.bool,
  preserveExpandedStateInLocation: PropTypes.bool,
};

NewTable.defaultProps = {
  renderSubComponent: undefined,
  noRecordsText: undefined,
  columns: [],
  data: [],
  isPending: false,
  isLoading: false,
  skeletonRowHeight: 40,
  'data-test': undefined,
  isDrawerOpen: false,
  columnVisibilityState: undefined,
  onDrawerClose: () => {},
  enableSelection: false,
  onColumnVisibilityChange: undefined,
  roundedTop: true,
  minColSize: 70,
  inlineScroll: false,
  id: undefined,
  restoreScroll: false,
  preserveExpandedStateInLocation: false,
};

export default NewTable;
