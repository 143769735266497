import cn from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import Collapse from '../Collapse';
import Drawer from '../Drawer';
import Icon from '../Icon';
import Switch from '../Switch';
import Tooltip from '../Tooltip';

const ColumnVisibilityDrawer = ({
  currentColumnVisibilityState,
  isOpen,
  onClose,
  onSave,
  table,
}) => {
  const { t } = useTranslation();
  const [newColumnVisibilityState, setNewColumnVisibilityState] = useState(
    currentColumnVisibilityState,
  );
  const [isDefaultColumnsOpen, setIsDefaultColumnsOpen] = useState(false);

  const onDefaultColumnsClick = () => {
    setIsDefaultColumnsOpen(!isDefaultColumnsOpen);
  };

  const onColumnVisibilityChange = (column) => {
    const columnNewState =
      newColumnVisibilityState[column.id] === undefined
        ? false
        : !newColumnVisibilityState[column.id];

    if (columnNewState) {
      setNewColumnVisibilityState((state) => {
        const newState = {};
        Object.keys(state).forEach((key) => {
          if (key !== column.id) {
            newState[key] = state[key];
          } else {
            newState[key] = columnNewState;
          }
        });
        return {
          ...newState,
        };
      });
    } else {
      setNewColumnVisibilityState({
        ...newColumnVisibilityState,
        [column.id]: columnNewState,
      });
    }
  };

  const defaultColumns = table
    .getAllColumns()
    .filter((column) => !column.getCanHide())
    .filter((column) =>
      column.columnDef.meta?.showInVisibilityToggle === undefined
        ? true
        : column.columnDef.meta?.showInVisibilityToggle,
    );

  const optionalColumns = table
    .getAllColumns()
    .filter((column) => column.getCanHide());

  useEffect(() => {
    if (!isOpen) {
      setNewColumnVisibilityState(currentColumnVisibilityState);
    }
  }, [currentColumnVisibilityState, isOpen]);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      header={<div>{t('Show/hide columns')}</div>}
      body={
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-[4px]">
            <button
              className="w-full border-none flex justify-between items-center"
              type="button"
              onClick={onDefaultColumnsClick}
            >
              <div className="flex flex-col gap-1">
                <span className="font-medium text-sm text-left">
                  {t('Default columns')} ({defaultColumns.length})
                </span>
                <span className="text-xs text-left text-grey-700">
                  {t('Cannot be hidden')}
                </span>
              </div>
              <Icon
                className="h-2.5 w-2.5"
                icon={isDefaultColumnsOpen ? 'chevronUp' : 'chevronDown'}
              />
            </button>
            <Collapse isOpen={isDefaultColumnsOpen}>
              <div className="pt-4 flex flex-col gap-3">
                {defaultColumns.map((column) => (
                  <div className="text-sm text-grey-900" key={column.id}>
                    {typeof column.columnDef.header === 'string'
                      ? column.columnDef.header
                      : column.columnDef?.meta?.headerLabel}
                  </div>
                ))}
              </div>
            </Collapse>
          </div>

          <div className="flex flex-col gap-3 divide-y divide-grey-200">
            {optionalColumns.map((column, index) => (
              <div
                className={cn(
                  'flex justify-between items-center',
                  index !== 0 && 'pt-3',
                )}
                key={column.id}
              >
                <div className="flex gap-1 items-center">
                  <span
                    className={cn(
                      'text-sm font-medium',
                      newColumnVisibilityState[column.id]
                        ? 'text-grey-900'
                        : 'text-grey-700',
                    )}
                  >
                    {typeof column.columnDef.header === 'string'
                      ? column.columnDef.header
                      : column.columnDef?.meta?.headerLabel}
                  </span>
                  {column.columnDef.meta?.visibilityDrawerTooltip && (
                    <Tooltip
                      text={column.columnDef.meta?.visibilityDrawerTooltip}
                    >
                      <Icon
                        className="h-4 w-4 text-grey-500 duration-200 hover:text-ui-info-blue"
                        icon="infoFilled"
                      />
                    </Tooltip>
                  )}
                </div>
                <Switch
                  checked={newColumnVisibilityState[column.id] || false}
                  onChange={() => onColumnVisibilityChange(column)}
                />
              </div>
            ))}
          </div>
        </div>
      }
      footer={
        <Button
          onClick={() => {
            onSave(newColumnVisibilityState);
            onClose();
          }}
          isFullWidth
          text={t('Save Changes')}
          variant="solidBlue"
        />
      }
    />
  );
};

export default ColumnVisibilityDrawer;

ColumnVisibilityDrawer.propTypes = {
  table: PropTypes.shape({
    getAllColumns: PropTypes.func,
    getState: PropTypes.func,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  currentColumnVisibilityState: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      [PropTypes.string]: PropTypes.bool,
    }),
  }).isRequired,
};
