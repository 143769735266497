import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Avatar from '../../../components/Avatar';
import Dropdown from '../../../components/Dropdown/Dropdown';
import Icon from '../../../components/Icon';

const Button = (props) => {
  const { isOpen, text, ...rest } = props;
  return (
    <button
      className={cn(
        'inline-flex h-8 flex-shrink-0 cursor-pointer appearance-none items-center justify-between gap-1 whitespace-nowrap rounded-md border border-transparent bg-transparent px-2 text-sm font-medium text-primary-dark transition-colors hover:bg-grey-200 hover:text-primary-dark focus:border-primary-yellow active:border-primary-yellow active:bg-grey-200',
      )}
      type="button"
      {...rest}
    >
      <Avatar size="s" text={text} variant="yellow" />
      <Icon
        className="h-2.5 w-2.5"
        icon={isOpen ? 'chevronBoldUp' : 'chevronBoldDown'}
      />
    </button>
  );
};

Button.propTypes = {
  isOpen: PropTypes.bool,
  text: PropTypes.string,
};

Button.defaultProps = {
  isOpen: false,
  text: '',
};

const ProfileDropdown = ({ firstName, lastName, onLogout }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const menuItems = [
    {
      icon: 'userFilled',
      iconClassName: 'text-grey-700',
      onClick: () => {
        navigate('/my-account');
      },
      text: t('My Account'),
    },
    {
      icon: 'power',
      iconClassName: 'text-grey-700',
      onClick: () => {
        onLogout();
      },
      text: t('Sign Out'),
    },
  ];

  const initials = firstName.substring(0, 1) + lastName.substring(0, 1);

  return (
    <Dropdown menu={menuItems}>
      {({ isOpen, toggle }) => (
        <Button isOpen={isOpen} text={initials} onClick={toggle} />
      )}
    </Dropdown>
  );
};

ProfileDropdown.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default ProfileDropdown;
