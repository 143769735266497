import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Alert from '../../../components/Alert';
import CopyToClipboardButton from '../../../components/CopyToClipboardButton';
import DrawerCard from '../../../components/Drawer/DrawerCard';
import DrawerTextRow from '../../../components/Drawer/DrawerTextRow';
import TourStopStatus from '../../../enums/TourStopStatus';
import TourHelper from '../../../helpers/TourHelper';
import tourStopPropType from '../../../prop-types/tourStopPropType';
import TaskBlock from './TaskBlock';
import TourStopInfoCard from './TourStopInfoCard';

const TourStopDetailsDrawerContent = (props) => {
  const { tour, tourStop } = props;
  const { t } = useTranslation();

  const tourCode = TourHelper.getTourCode(tour);

  const firstUnit = tourStop.tasks[0]?.tourStopUnits[0]?.unit;

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col text-sm">
          <DrawerTextRow
            className="mb-4"
            title={t('Tour Code')}
            content={
              <div className="flex items-center justify-end gap-[2px]">
                <CopyToClipboardButton
                  info={tourCode}
                  tooltipText={t('Copy Tour Code')}
                />
                <span className="break-words">{tourCode}</span>
              </div>
            }
          />

          <TourStopInfoCard tourStop={tourStop} />

          <DrawerCard className="mt-4" title={t('Address')}>
            <div className="flex flex-col gap-2 pt-2">
              <DrawerTextRow
                className="text-primary-dark"
                title={tourStop.address}
                collapsedContent={
                  (tourStop.what3words ||
                    firstUnit?.addressNote ||
                    firstUnit?.endCustomerNote) && (
                    <div className="text-sm">
                      {firstUnit?.addressNote && (
                        <div>{firstUnit?.addressNote}</div>
                      )}
                      {firstUnit?.endCustomerNote && (
                        <div>{firstUnit?.endCustomerNote}</div>
                      )}
                      {tourStop.what3words && (
                        <div>{`///${tourStop.what3words}`}</div>
                      )}
                    </div>
                  )
                }
              />
            </div>
          </DrawerCard>

          {tourStop.unitsBlocked &&
            tourStop.status !== TourStopStatus.Withdrawn && (
              <Alert
                className="mt-4"
                variant="warningOrange"
                message={t(
                  'The issues on significant Units blocked their execution.',
                )}
              />
            )}

          {tourStop.status === TourStopStatus.Withdrawn && (
            <Alert
              className="mt-4"
              variant="error"
              message={t(
                "Stop couldn't be attempted due to Unit issues within.",
              )}
            />
          )}

          <div className="text-sm font-medium mt-6 mb-6">{t('Tasks')}</div>

          {tourStop.tasks.map((task) => (
            <TaskBlock
              key={task.id}
              task={task}
              tourStopStatus={tourStop.status}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

TourStopDetailsDrawerContent.propTypes = {
  tourStop: tourStopPropType.isRequired,
  tour: PropTypes.shape({
    vehicle: PropTypes.string,
    date: PropTypes.string,
    shift: PropTypes.number,
  }).isRequired,
};

export default TourStopDetailsDrawerContent;
