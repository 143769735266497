import { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Switch from '../../../components/Switch';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import ChangeParcelStatusContext from './ChangeUnitStatusContext';

const InClarification = () => {
  const { t } = useTranslation();
  const { reset, setValue, trigger } = useFormContext();

  const changeParcelStatusContext = useContext(ChangeParcelStatusContext);

  const { defaultValues = {} } = changeParcelStatusContext;
  const { status: originalStatus } = defaultValues;

  const isInitiallyChecked =
    originalStatus === DeliveryTaskUnitStatus.InClarification;
  const [checked, setChecked] = useState(isInitiallyChecked);

  return (
    <div className="flex flex-col">
      <div
        className="flex min-h-[40px] items-center gap-2"
        data-test="inClarification-switch"
      >
        <Switch
          checked={checked}
          onChange={(val) => {
            if (isInitiallyChecked === val) {
              setChecked(isInitiallyChecked);
              reset();
              return;
            }

            trigger('statusNote');
            setValue('deliveryOption', null);
            setValue('deliveryOptionNote', '');
            setValue('notifyCustomer', false);
            setValue('statusNote', '');
            setValue('unitSpecialCase', '');
            setValue('postponeReason', '');
            setValue('returnReason', '');
            setValue('failureReason', '');
            setValue('postponeReasonNote', '');
            setValue(
              'status',
              val ? DeliveryTaskUnitStatus.InClarification : '',
              {
                shouldDirty: val,
              },
            );
            setChecked(val);
          }}
        />
        <span className="text-sm font-medium">{t('In clarification')}</span>
      </div>
      <div className="text-grey-700 text-sm">
        {t(
          "Activate the 'In clarification' state for Units with uncertain status or those that have undergone an unusual flow and require clarification.",
        )}
      </div>
    </div>
  );
};

export default InClarification;
