import eventType from '../eventType';
import ExecutionField from './ExecutionField';
import NoteField from './NoteField';
import StatusField from './StatusField';
import UserField from './UserField';

const FoundEvent = (props) => {
  const { event } = props;
  return (
    <>
      <StatusField event={event} />
      {event.changeNote && <NoteField note={event.changeNote} />}
      {event.autoExecutionSlotChange && <ExecutionField event={event} />}
      <UserField event={event} />
    </>
  );
};

FoundEvent.propTypes = {
  event: eventType.isRequired,
};

export default FoundEvent;
