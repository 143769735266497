import cn from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useKeyPressEvent } from 'react-use';

import useDropdownList from '../../hooks/useDropdownList';
import Icon from '../Icon';
import Label, { labelVariantsMap } from '../Label';
import Dropdown from './Dropdown';
import { optionPropType } from './Option';
import SelectContext from './SelectContext';
import useIndexedOptions from './useIndexedOptions';

const MultiValue = (props) => {
  const { onRemoveClick, option, readOnly, variant } = props;

  const onDismissClick = (e) => {
    e.stopPropagation();
    onRemoveClick(option.value);
  };

  return (
    <Label
      size="s"
      text={option.label}
      variant={variant}
      onDismissClick={readOnly ? undefined : onDismissClick}
    />
  );
};

MultiValue.propTypes = {
  onRemoveClick: PropTypes.func.isRequired,
  option: optionPropType.isRequired,
  readOnly: PropTypes.bool,
  variant: PropTypes.oneOf(Object.keys(labelVariantsMap)),
};

MultiValue.defaultProps = {
  readOnly: false,
  variant: 'grey',
};

const MultiSelect = (props) => {
  const {
    className,
    disabled,
    error,
    getLabelVariant,
    onBlur,
    onChange,
    options,
    placeholder,
    readOnly,
    value,
  } = props;

  const dropdownToggleRef = useRef(undefined);
  const valuesContainerRef = useRef(undefined);
  const clearButtonRef = useRef(undefined);

  const [floatingReferenceElement, setFloatingReferenceElement] =
    useState(null);
  const indexedOptions = useIndexedOptions(options);

  const filteredOptions = useMemo(() => {
    const tempArray = indexedOptions.filter((option) =>
      Array.isArray(value) ? !value.includes(option.value) : [],
    );

    return tempArray.map((option, index) => ({ ...option, index }));
  }, [indexedOptions, value]);

  const onSelect = useCallback(
    (option) => {
      const newValue = option.value;
      const newValueArray = Array.isArray(value)
        ? [...value, newValue]
        : [newValue];
      onChange(newValueArray);
      onBlur();
    },
    [onBlur, onChange, value],
  );

  const onRemove = useCallback(
    (removeValue) => {
      const newValueArray = Array.isArray(value)
        ? value.filter((entry) => entry !== removeValue)
        : [];

      if (newValueArray.length > 0) {
        onChange(newValueArray);
      } else {
        onChange([]);
      }

      onBlur();
    },
    [onBlur, onChange, value],
  );

  const { activePath, getOptionProps, getRootProps, isOpen, toggle } =
    useDropdownList({
      onSelect,
      options: filteredOptions,
    });

  useKeyPressEvent('Escape', () => {
    if (isOpen) {
      toggle();
    }
  });

  const handleBlur = (e) => {
    const isClickOnOption = filteredOptions.find(
      (option) => option.id === e.relatedTarget?.dataset?.optionId,
    );
    if (isClickOnOption) {
      return;
    }

    if (isOpen) {
      toggle();
    }
    onBlur();
  };

  const hasValue = Array.isArray(value) && value.length > 0;

  const selectContextValue = useMemo(
    () => ({
      activePath,
      getOptionProps,
      value,
      onSelect,
    }),
    [activePath, getOptionProps, onSelect, value],
  );

  return (
    <SelectContext.Provider value={selectContextValue}>
      <div className={cn('relative', className)} {...getRootProps()}>
        <div className="relative" ref={setFloatingReferenceElement}>
          <div
            className={cn(
              'flex min-h-[40px] w-full cursor-pointer flex-wrap items-center gap-2 gap-y-2 truncate rounded-md border py-[7px] pl-3 text-sm focus:border-primary-yellow',
              error ? 'border-ui-red' : 'border-grey-500',
              value ? 'pr-14' : 'pr-10',
              disabled && 'pointer-events-none bg-grey-200 opacity-40',
              readOnly && 'pointer-events-none bg-grey-200',
            )}
            ref={valuesContainerRef}
            tabIndex={-1}
            onBlur={handleBlur}
            onClick={() => {
              if (readOnly) {
                return false;
              }

              toggle();
              return undefined;
            }}
          >
            {hasValue && options ? (
              options.map((option) => {
                if (value.includes(option.value)) {
                  return (
                    <MultiValue
                      key={option.value}
                      option={option}
                      readOnly={readOnly}
                      variant={getLabelVariant[option?.value]}
                      onRemoveClick={onRemove}
                    />
                  );
                }
                return null;
              })
            ) : (
              <span className="truncate text-grey-700">{placeholder}</span>
            )}
          </div>
          {hasValue && !disabled && !readOnly && (
            <>
              <Icon
                className="absolute right-[38px] top-1/2 h-3 w-3 -translate-y-1/2 text-primary-dark"
                icon="close"
                ref={clearButtonRef}
                onClick={() => {
                  onChange([]);
                  onBlur();
                  if (isOpen) {
                    toggle();
                  }
                }}
              />
              <span className="pointer-events-none absolute right-[30px] top-1/2 h-[calc(100%_-_20px)] w-[1px] -translate-y-1/2 rounded-[1px] bg-grey-500" />
            </>
          )}
          {!readOnly && (
            <div className="absolute bottom-0 right-3 top-0 flex items-center justify-center">
              <button
                className={cn('cursor-pointer', disabled && 'opacity-40')}
                disabled={disabled}
                ref={dropdownToggleRef}
                type="button"
                onClick={() => {
                  toggle();
                  if (!isOpen) {
                    valuesContainerRef?.current?.focus();
                  }
                }}
              >
                <Icon
                  className="h-2.5 w-2.5"
                  icon={isOpen ? 'chevronBoldUp' : 'chevronBoldDown'}
                />
              </button>
            </div>
          )}
        </div>
        {isOpen && (
          <Dropdown
            options={filteredOptions}
            floatingReferenceElement={floatingReferenceElement}
          />
        )}
      </div>
    </SelectContext.Provider>
  );
};

MultiSelect.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  getLabelVariant: PropTypes.oneOfType([PropTypes.object]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(optionPropType),
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
};

MultiSelect.defaultProps = {
  className: undefined,
  disabled: false,
  error: '',
  getLabelVariant: {},
  onBlur: () => {},
  onChange: () => {},
  options: [],
  placeholder: '',
  readOnly: false,
  value: undefined,
};

export default MultiSelect;
